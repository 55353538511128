<template>
  <div class="d-print-none" v-if="this.$store.state.message.show">
    <v-snackbar
      class="text-center"
      v-model="showMessage"
      multi-line
      :color="color"
      elevation="9"
      timeout="-1"
      :vertical="true"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn outlined v-bind="attrs" @click="fechaMessage">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Feedback",
  data() {
    return {};
  },
  computed: {
    message() {
      return this.$store.state.message.text;
    },
    color() {
      return this.$store.state.message.color;
    },
    showMessage() {
      return this.$store.state.message.show;
    },
  },

  methods: {
    fechaMessage() {
      this.$store.commit("zeraMessage");
    },
  },
};
</script>

<style scoped></style>
