import Vue from "vue";
import Vuex from "vuex";
import { api } from "@/conf/api";
import utilitario from "@/conf/utilitario";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    message: {
      text: "",
      color: "",
      show: false,
    },
    jaFiz : 1,
    dividas: [],
    cpf: null,
    divida_atual: null,
    pacote_atual: "",
    negoc_atual: null,
    negoc_atual_algar: null,
    num_acordo_atual: "",
    quantas_parcelas: 1,
    parcelas_faltam: 0,
    faturas_atual: null,
    venc_atual: null,
    credor_atual: "",
    acordos: [],
    mostraImprimir: false,
    mostraVoltar: false,
    emailCli: "",
    boleto: null,
    operacaoBoletos: null,
    boletoTimOferta: null,
    dialogo: false,
    aparecebanner: true,
    divida_oi_nova: null,
    divida_oi_nova2: null,
    divida_oi_nova_resp: null,
    boleto_viasat: null,
    unismg_faturas: null,
    unismg_criterios: null,
    unismg_valores: null,
  },

  mutations: {
    setUnismgValores(state,p) {
      state.unismg_valores=p;
    },
    setUnismgFaturas(state,p) {
      state.unismg_faturas=p;
    },
    setUnismgCriterios(state,p) {
      state.unismg_criterios=p;
    },
    setJaFiz(state) {
      state.jaFiz++;
    },
    setBolViasat(state, payload) {
      state.boleto_viasat = payload;
    },
    setDividaOiNova(state, payload) {
      state.divida_oi_nova = payload;
    },
    setDividaOiNova2(state, payload) {
      state.divida_oi_nova2 = payload;
    },
    setDividaOiNovaResp(state, payload) {
      state.divida_oi_nova_resp = payload;
    },
    setApareceBanner(state, payload) {
      state.aparecebanner = payload;
    },
    setDialogo(state, payload) {
      state.dialogo = payload;
    },
    setOperacaoBoletos(state, payload) {
      state.operacaoBoletos = payload;
    },
    setOfertaTim(state, payload) {
      state.boletoTimOferta = payload;
    },
    setNumAcordoAtual(state, payload) {
      state.num_acordo_atual = payload;
    },
    setNegocAlgar(state, payload) {
      state.negoc_atual_algar = payload;
    },
    setMostraImprimir(state, payload) {
      state.mostraImprimir = payload;
    },
    setBoleto(state, payload) {
      state.boleto = payload;
    },
    setMostraVoltar(state, payload) {
      state.mostraVoltar = payload;
    },
    setQuantasParcelas(state, payload) {
      state.quantas_parcelas = payload;
    },
    setParcelasFaltam(state, payload) {
      state.parcelas_faltam = payload;
    },
    setDividaAtual(state, payload) {
      state.divida_atual = payload;
    },
    setVencAtual(state, payload) {
      state.venc_atual = payload;
    },

    setCredorAtual(state, payload) {
      state.credor_atual = payload;
    },
    setNegocAtual(state, payload) {
      state.negoc_atual = payload;
    },
    setPacoteAtual(state, payload) {
      state.pacote_atual = payload;
    },
    setFaturaAtual(state, payload) {
      state.faturas_atual = payload;
    },
    setLogged(state, payload) {
      state.logged = payload;
    },
    setCpf(state, payload) {
      state.cpf = payload;
    },
    showMessage(state, payload) {
      state.message = {
        text: payload.text,
        color: payload.color,
        show: payload.show,
      };
    },
    zeraMessage(state) {
      state.message = {
        text: "",
        type: "",
        show: false,
      };
    },
    insereDividas(state, pay) {
      state.dividas = pay;
    },
    setEmail(state, pay) {
      state.emailCli = pay;
    },
  },
  actions: {
    insereUnismgValores(state,p){
      state.commit("setUnismgValores",p)
    },
    insereUnismgFaturas(state,p){
      state.commit("setUnismgFaturas",p)
    },
    insereUnismgCriterios(state,p){
      state.commit("setUnismgCriterios",p)
    },
    insereJaFiz(state) {
      state.commit("setJaFiz");
    },
    insereBolViasat(state, pay) {
      state.commit("setBolViasat", pay);
    },
    insereDividaOiNova(state, pay) {
      state.commit("setDividaOiNova", pay);
    },
    insereDividaOiNova2(state, pay) {
      state.commit("setDividaOiNova2", pay);
    },
    insereDividaOiNovaResp(state, pay) {
      state.commit("setDividaOiNovaResp", pay);
    },
    insereDividas2(state, pay) {
      pay.map(async (item) => {
        if (item.cpcgc == "00354301000017") {
          //item.cpsaldev = 9999;
          let atraso = item.diasatraso;
          try {
            let res = await api.get(`especial/dscard/${atraso}/1`);
            let corr_mora = Number(res.data.dados[0].correcao_mora);

            let p1 = (atraso - 60) * Number(item.cpsaldev);
            let p2 = corr_mora / 100 / 30;
            let mora = p1 * p2;

            //console.log(utilitario.casasDecimais(mora, 2), typeof mora);
            item.saldo_ori = item.cpsaldev;
            item.mora = mora;
            let valor =
              Number(item.cpsaldev) + Number(utilitario.casasDecimais(mora, 2));
            item.cpsaldev = valor;
          } catch (e) {
            this.$router.push("erroPage");
          }
        }
      });
      state.commit("insereDividas", pay);
    },
    insereCPF(state, pay) {
      state.commit("setCpf", pay);
    },
    insereOfertaTim(state, pay) {
      state.commit("setOfertaTim", pay);
    },
    marcaDividaAtual(state, pay) {
      state.commit("setDividaAtual", pay);
    },
    marcaFaturaAtual(state, pay) {
      state.commit("setFaturaAtual", pay);
    },
    marcaPacoteAtual(state, pay) {
      state.commit("setPacoteAtual", pay);
    },
    marcaNegocAtual(state, pay) {
      state.commit("setNegocAtual", pay);
    },
    marcaNegocAlgar(state, pay) {
      state.commit("setNegocAlgar", pay);
    },
    marcaCredorAtual(state, pay) {
      state.commit("setCredorAtual", pay);
    },

    marcaVencAtual(state, pay) {
      state.commit("setVencAtual", pay);
    },
    marcaEmail(state, pay) {
      state.commit("setEmail", pay);
    },
    quantasParcelas(state, pay) {
      state.commit("setQuantasParcelas", pay);
    },
    quantasParcelasFaltam(state, pay) {
      state.commit("setParcelasFaltam", pay);
    },
    guardaBoleto(state, pay) {
      state.commit("setBoleto", pay);
    },
    guardaNumAcordo(state, pay) {
      state.commit("setNumAcordoAtual", pay);
    },
    guardaOpercaoBoletos(state, pay) {
      state.commit("setOperacaoBoletos", pay);
    },
    setMostraBotoes(state, pay) {
      state.commit("setMostraImprimir", pay.imprimir);
      state.commit("setMostraVoltar", pay.voltar);
    },
  },
  modules: {},
});
