<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="py-5" elevation="8">
          <h1
            class="text-center primary--text text-h4"
            style="font-family: Kelly Slab !important"
          >
            Planos para o CPF/CNPJ {{ $store.state.cpf }}
          </h1>
          <h4 class="text-center">
            Data:
            {{
              new Date()
                .getDate()
                .toString()
                .padStart(2, "0")
            }}/ {{ (new Date().getMonth() + 1).toString().padStart(2, "0") }}/
            {{ new Date().getFullYear() }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" v-if="mostraConsolidado">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line>
            <v-list-item-content>
              <div
                class="overline mb-4"
                style="font-family: Kelly Slab !important"
              >
                {{ credor }}
              </div>
              <v-list-item-title class="headline mb-1"
                >A Vista</v-list-item-title
              >
              <v-list-item-subtitle>
                <div class="text--primary">
                  Valor R$ {{ consolidado.valor | dinheiro }}
                </div>
                <div class="text--primary">*****</div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn color="primary" @click="negociarConsol">
              Negociar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col xs="12" v-if="mostraParcelado">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">{{ credor }}</div>
              <v-list-item-title class="headline mb-1"
                >Parcelado</v-list-item-title
              >
              <v-list-item-subtitle>
                <div class="text--primary">
                  Entrada de R$ {{ parcelado.valor | dinheiro }}
                </div>
                <div class="text--primary">
                  Mais {{ parcelado.parcelas }} X de R$
                  {{ parcelado.valorP | dinheiro }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn text color="deep-purple accent-4" @click="negociarParc">
              Negociar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col xs="12" v-if="mostraFatura">
        <v-card class="mx-auto" max-width="344">
          <v-card-title class="headline mb-1">Faturas</v-card-title>
          <v-list-item three-line v-for="(item, i) in faturas" :key="i">
            <v-list-item-content>
              <div class="overline mb-4">{{ credor }}</div>
              <v-list-item-title class="headline mb-1">
                Fatura {{ item.pdnumdoc }}</v-list-item-title
              >
              <v-list-item-subtitle>
                <div class="text--primary">
                  Valor R$ {{ item.pdvalor | dinheiro }}
                </div>
                <div class="text--primary mb-2">
                  Vencimento Original
                  {{ item.pddtvencto | dataF }}
                </div>
              </v-list-item-subtitle>
              <v-btn
                text
                color="deep-purple accent-4"
                @click="negociarFatura(item)"
              >
                Negociar
              </v-btn>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { api } from "@/conf/api";

export default {
  name: "PlanosPage",
  data() {
    return {
      credor: "",
      mostraConsolidado: false,
      mostraParcelado: false,
      mostraFatura: true,
      faturas: [],
      consolidado: {
        valor: 0,
        parcelas: 1,
        codbarras: "",
        valorP: 0,
      },
      parcelado: {
        valor: 0,
        parcelas: 1,
        codbarras: "",
        valorP: 0,
      },
      picker: new Date().toISOString().substr(0, 10),
      picker2: new Date().toISOString().substr(0, 10),
      picker3: new Date().toISOString().substr(0, 10),
    };
  },
  async beforeMount() {
    this.credor = this.$store.state.credor_atual;
    let cgc_credor = this.$store.state.divida_atual.cpcgc;
    let cpf = this.$store.state.divida_atual.cpcpfcgc;
    let contrato = this.$store.state.divida_atual.contrato;
    let datarem = this.$store.state.divida_atual.cpdatrem.substr(0, 10);
    console.log(cgc_credor, cpf, contrato); //  31404878000116
    if (cgc_credor == "31404878000116") {
      return this.$router.push("PlanoUnismg");
    }
    if (cgc_credor == "27001440000110") {
      return this.$router.push("PlanoViasat");
    }
    if (cgc_credor == "71208516000174") {
      return this.$router.push("PlanoAlgar");
    }
    if (cgc_credor == "00354301000017") {
      return this.$router.push("PlanoE");
    }
    if (cgc_credor == "10233159000100") {
      return this.$router.push("PlanoEnalin");
    }
    if (cgc_credor == "33044983000116") {
      return this.$router.push("PlanoImpecavel");
    }
    if (
      cgc_credor == "05423963000111" ||
      cgc_credor == "33000118000179" ||
      cgc_credor == "33000118000190" ||
      cgc_credor == "04164616000158"
    ) {
      //return (window.location = "https://www.oi.com.br/negociacao");
      //"/cpfcontrato/:cpf/:contrato/:cgc?/:datarem?/:sistema?"
      let url = `telemanova/oi/cpfcontrato/${cpf}/${contrato}/${cgc_credor}/${datarem}/site`;
      console.log("urlll", url);
      try {
        let resposta = await api.get(url);
        // console.log("resp oi", resposta.data.dados);
        let dividaOiNova = resposta.data;
        if (dividaOiNova.status != "200") throw new Error("Oooops2!");
        this.$store.dispatch("insereDividaOiNova", dividaOiNova);
        return this.$router.push("PlanoOiNova");
      } catch (e) {
        return this.$router.push({ name: "ErroPage" });
      }
    }
    try {
      await this.pegaFaturas();
      await this.pegaC();
      // pedido para tirar o parcelado da oi e brt tudo
      // await this.pegaP();
    } catch (e) {
      console.log(e);
    }
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  methods: {
    async pegaC() {
      let cgc = this.$store.state.divida_atual.cpcgc;

      // let codbarras = this.$store.state.divida_atual.cpobs.trim();
      let temcodigo = false;
      let valorC = 0;
      let val1 = "";
      let val2 = "";
      let val3 = "";
      let cAux = "";
      //  let valorP = "";
      //  let p = "";
      let c = this.$store.state.divida_atual.cpobs;

      if (c == null || c == undefined) {
        return;
      }
      c = c.trim();
      if (c.length < 44) {
        return;
      }
      //  let totalP = "";
      //if (cgc == "04164616000158") {
      // oi movel só fatura - não tem c
      //}
      if (cgc == "33000118000190" || cgc == "33000118000183") {
        // brt e brt camp
        //84610000001502600200118907452020045420400424
        if (c.length == 44) {
          val1 = c.substr(6, 7);
          val2 = c.substr(13, 2);
          valorC = Number(`${val1}.${val2}`);
          temcodigo = true;
        } else {
          return;
        }
      }
      if (cgc == "04164616000160" || cgc == "04164616000158") {
        // oi movel camp e oi movel
        //Consolidado = 84690000013-0  02290113247-6  93529560409-1  44082900100-5 / Parcelado =84670000004-1  47680113247-5  93529560409-1  42657200100-3 /
        cAux = c.split("/");
        c = cAux[0].trim();
        if (typeof c == "string") {
          if (c.startsWith("Consolidado")) {
            cAux = c.split("=");
            c = cAux[1].trim();
            cAux = c.replace(/\D/gim, "");
            val1 = cAux.substr(5, 6);
            val2 = cAux.substr(12, 2);
            val3 = cAux.substr(14, 2);
            valorC = Number(`${val1}${val2}.${val3}`);
            temcodigo = true;
          }
        } else {
          return;
        }
      }
      if (
        cgc == "33000118000181" ||
        cgc == "33000118000179" ||
        cgc == "01575693000103"
      ) {
        // telemar camp e oi fixo
        //Consolidado = 846000000006985900240203248882202737667000700050 / Parcelado = 846400000002591400240204248882202737667000700043
        cAux = c.split("/");
        c = cAux[0].trim();
        if (typeof c == "string") {
          if (c.startsWith("Consolidado")) {
            cAux = c.split("=");
            c = cAux[1].trim();
            val1 = c.substr(5, 6);
            val2 = c.substr(12, 2);
            val3 = c.substr(14, 2);
            valorC = Number(`${val1}${val2}.${val3}`);
            temcodigo = true;
          }
        } else {
          return;
        }
      }
      if (
        (cgc == "33000118000190" ||
          cgc == "33000118000183" ||
          cgc == "04164616000160" ||
          cgc == "33000118000181" ||
          cgc == "01575693000103" ||
          cgc == "33000118000179" ||
          cgc == "04164616000158") &&
        temcodigo
      ) {
        this.mostraConsolidado = true;
        this.mostraFatura = false; //mudar para false depois dos testes
        this.consolidado = {
          valor: valorC,
          parcelas: 1,
          codbarras: c,
          valorP: 0,
        };
      }
    },
    async pegaP() {
      let cgc = this.$store.state.divida_atual.cpcgc.trim();

      // let codbarras = this.$store.state.divida_atual.cpobs.trim();
      let temcodigo = false;
      let cpf = this.$store.state.divida_atual.cpcpfcgc.trim();
      let contrato = this.$store.state.divida_atual.contrato.trim();
      // let valorC = "";
      let valorP = 0;
      let valorP2 = 0;
      let val1 = "";
      let val2 = "";
      let val3 = "";
      let pAux = "";

      let totalP = 0;
      let pegarParcelado = "";

      let p = this.$store.state.divida_atual.cpobs;

      if (p == null || p == undefined) {
        return;
      }
      p = p.trim();
      if (p.length < 44) {
        return;
      }
      //if (cgc == "04164616000158") {
      // oi movel só fatura - não tem p
      //}
      if (cgc == "04164616000160") {
        // oi movel camp
        ////Consolidado = 84690000013-0  02290113247-6  93529560409-1  44082900100-5 / Parcelado =84670000004-1  47680113247-5  93529560409-1  42657200100-3 /
        pAux = p.split("/");
        p = pAux[1].trim();
        if (typeof p == "string") {
          if (p.startsWith("Parcelado")) {
            pAux = p.split("=");
            p = pAux[1].trim();
            pAux = p.replace(/\D/gim, "");
            val1 = pAux.substr(5, 6);
            val2 = pAux.substr(12, 2);
            val3 = pAux.substr(14, 2);
            valorP = Number(`${val1}${val2}.${val3}`);

            pegarParcelado = this.$store.state.faturas_atual.filter(function(
              value
            ) {
              return value.pdtipofatura == 3;
            });
            if (pegarParcelado) {
              valorP2 = Number(pegarParcelado[0].pavalorfatura);
              totalP = Number(pegarParcelado[0].pdqtdfatura);
              // totalP++;
              temcodigo = true;
            } else {
              return;
            }
          }
        } else {
          return;
        }
      }
      if (cgc == "33000118000181" || cgc == "01575693000103") {
        // telemar camp
        ////Consolidado = 846000000006985900240203248882202737667000700050 / Parcelado = 846400000002591400240204248882202737667000700043
        pAux = p.split("/");

        p = pAux[1].trim();

        if (typeof p == "string") {
          if (p.startsWith("Parcelado")) {
            pAux = p.split("=");
            p = pAux[1].trim();
            val1 = p.substr(5, 6);
            val2 = p.substr(12, 2);
            val3 = p.substr(14, 2);
            valorP = Number(`${val1}${val2}.${val3}`);

            pegarParcelado = this.$store.state.faturas_atual.filter(function(
              value
            ) {
              return value.pdtipofatura == 3;
            });
            if (pegarParcelado) {
              valorP2 = Number(pegarParcelado[0].pavalorfatura);
              totalP = Number(pegarParcelado[0].pdqtdfatura);
              temcodigo = true;
            } else {
              return;
            }
          }
        } else {
          return;
        }
      }
      if (cgc == "33000118000179") {
        // oi fixo
        ////Consolidado = 846500000001938700240303328888103467927320100021 / Parcelado = 846000000006331200240306328888103467927320100013
        pAux = p.split("/");
        p = pAux[1].trim();
        if (typeof p == "string") {
          if (p.startsWith("Parcelado")) {
            pAux = p.split("=");
            p = pAux[1].trim();
            val1 = p.substr(5, 6);
            val2 = p.substr(12, 2);
            val3 = p.substr(14, 2);
            valorP = Number(`${val1}${val2}.${val3}`);

            pegarParcelado = await api.get(
              `http://200.164.113.50:3000/parcelas/oifixo/${cpf}/${contrato}`
            );
            if (pegarParcelado) {
              valorP2 = pegarParcelado.data.dados[0].pavalorfatura
                .replace(",", ".")
                .replace("R$", "");
              totalP = Number(pegarParcelado.data.dados[0].pdqtdfatura);
              temcodigo = true;
            } else {
              return;
            }
          }
        } else {
          return;
        }
      }
      if (
        (cgc == "04164616000160" ||
          cgc == "33000118000181" ||
          cgc == "01575693000103" ||
          cgc == "33000118000179") &&
        temcodigo
      ) {
        console.log("entrou no obj", p);
        this.mostraParcelado = true;
        this.mostraFatura = false;
        this.parcelado = {
          valor: valorP,
          parcelas: totalP,
          codbarras: p,
          valorP: valorP2,
        };
      }
      // console.log(this.parcelado);
    },
    async pegaFaturas() {
      try {
        let contrato = this.$store.state.divida_atual.contrato;
        let cgc = this.$store.state.divida_atual.cpcgc;
        let rem = this.$store.state.divida_atual.cpdatrem.substr(0, 10);
        let cpf = this.$store.state.cpf;

        let res = await api.get(`faturas/${cpf}/${contrato}/${cgc}/${rem}`);
        if (res.data.dados.length > 0) {
          await this.$store.dispatch("marcaFaturaAtual", res.data.dados);
          let faturas = this.$store.state.faturas_atual.filter(function(value) {
            return value.pdtipofatura == null || value.pdtipofatura == 1;
          });
          this.faturas = faturas;
        } else {
          this.$router.push({ name: "ErroPage" });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async negociarFatura(item) {
      //let cpf = this.$store.state.cpf;
      // let contrato = this.$store.state.divida_atual.contrato;
      //console.log(item);
      try {
        await this.$store.dispatch("marcaPacoteAtual", "F");
        await this.$store.dispatch("marcaNegocAtual", item);
        //await this.$store.dispatch("marcaVencAtual", this.picker);
        //this.$router.push(this.$store.state.divida_atual.cpcgc);
        this.$router.push("PlanoF");
      } catch (e) {
        console.log(e);
      }
    },
    async negociarParc() {
      //
      try {
        await this.$store.dispatch("marcaPacoteAtual", "P");
        await this.$store.dispatch("marcaNegocAtual", this.parcelado);

        await this.$store.dispatch(
          "quantasParcelasFaltam",
          this.parcelado.parcelas
        );
        //await this.$store.dispatch("marcaVencAtual", this.picker);
        //this.$router.push(this.$store.state.divida_atual.cpcgc);
        this.$router.push("PlanoP");
      } catch (e) {
        console.log(e);
      }
    },
    async negociarConsol() {
      //
      try {
        await this.$store.dispatch("marcaPacoteAtual", "C");
        await this.$store.dispatch("marcaNegocAtual", this.consolidado);
        //await this.$store.dispatch("marcaVencAtual", this.picker);
        //this.$router.push(this.$store.state.divida_atual.cpcgc);
        this.$router.push("PlanoC");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
