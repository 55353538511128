<template>
  <v-app>
    <v-main fluid class="" style=" padding: 0 !important;">
      <router-view></router-view>
     <!-- <v-container class="d-print-none" v-if="this.$store.state.message.show"> -->
        <feedBack />
     <!-- </v-container>  -->
      <Dialogo />
      <MeuFooter />
    </v-main>
  </v-app>
</template>

<script>
import FeedBack from "./components/helper/FeedBack";
import MeuFooter from "./components/helper/MeuFooter";
import Dialogo from "./components/helper/Dialogo";
//blue-grey lighten-5
export default {
  name: "App",

  components: {
    FeedBack,
    MeuFooter,
    Dialogo,
  },

  data: () => ({
    //
  }),
};
</script>

<style>

* {
  font-family: "Fjalla One", sans-serif !important;
}
</style>
