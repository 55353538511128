<template>
  <v-row justify="center" v-if="this.$store.state.dialogo">
    <v-dialog v-model="diag" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h4" style="font-family: Kelly Slab !important"
            >Política de Privacidade
          </span>
        </v-card-title>
        <v-card-text class="text-caption">
          Todas as suas informações pessoais obtidas serão usadas para ajudar a
          tornar a sua visita no nosso site a mais produtiva e agradável
          possível.<br />
          A garantia da confidencialidade dos dados pessoais dos utilizadores do
          nosso site é importante para a Easycob.<br />
          Todas as informações pessoais relativas a clientes ou visitantes que
          usem o Negocie Aqui Easycob serão tratadas em concordância com a Lei
          da Proteção de Dados Pessoais de 26 de outubro de 1998 (Lei n.º
          67/98).<br />
          A informação pessoal recolhida pode incluir o seu nome, cpf, e-mail,
          números de telefones, morada, data de nascimento e/ou outros.<br />
          O uso do Negocie Aqui pressupõe a aceitação deste acordo de
          privacidade. A equipe do Negocie Aqui reserva-se ao direito de alterar
          este acordo sem aviso prévio. Deste modo, recomendamos que consulte a
          nossa Política de Privacidade com regularidade de forma a estar sempre
          atualizado.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" @click="naoaceitar" class="white--text">
            Rejeitar
          </v-btn>
          <v-btn color="primary" @click="aceitar"> Aceitar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "Dialogo",
  computed: {
    diag: {
      get() {
        return this.$store.state.dialogo;
      },
      set(value) {
        this.$store.commit("setDialogo", value);
      },
    },
  },
  methods: {
    aceitar() {
      this.$store.commit("setDialogo", false);
      this.$store.commit("setApareceBanner", false);
    },
    naoaceitar() {
      this.$store.commit("setDialogo", false);
      this.$store.commit("setApareceBanner", true);
    },
  },
};
</script>

<style></style>
