import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "@/components/login/LoginPage.vue";
import LoginPage2 from "@/components/login2/LoginPage2.vue";
import DividasPage from "@/components/dividas/DividasPage.vue";
import PlanosPage from "@/components/planos/PlanosPage.vue";
import AcordosPage from "@/components/acordos/AcordosPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/loginteste",
    name: "LoginTeste",
    component: () =>
      import(/* webpackChunkName: "logintesteeeee" */ "@/components/teste/LoginTeste.vue"),
  },
  {
    path: "/boleto2via",
    name: "LoginPage2",
    component: LoginPage2,
  },
  {
    path: "/dividas",
    name: "DividasPage",
    component: DividasPage,
  },
  {
    path: "/planos",
    name: "PlanosPage",
    component: PlanosPage,
  },
  {
    path: "/planof",
    name: "PlanoF",
    component: () =>
      import(/* webpackChunkName: "planof" */ "@/components/planos/PlanoF.vue"),
  },
  {
    path: "/planoc",
    name: "PlanoC",
    component: () =>
      import(/* webpackChunkName: "planoc" */ "@/components/planos/PlanoC.vue"),
  },
  {
    path: "/planop",
    name: "PlanoP",
    component: () =>
      import(/* webpackChunkName: "planop" */ "@/components/planos/PlanoP.vue"),
  },
  {
    path: "/planoe",
    name: "PlanoE",
    component: () =>
      import(/* webpackChunkName: "planoe" */ "@/components/planos/PlanoE.vue"),
  },
  {
    path: "/planoe2",
    name: "PlanoE2",
    component: () =>
      import(
        /* webpackChunkName: "planoe" */ "@/components/planos/PlanoE2.vue"
      ),
  },
  {
    path: "/planoenalin",
    name: "PlanoEnalin",
    component: () =>
      import(
        /* webpackChunkName: "planoe" */ "@/components/planos/PlanoEnalin.vue"
      ),
  },
  {
    path: "/planounismg",
    name: "PlanoUnismg",
    component: () =>
      import(
        /* webpackChunkName: "plawnoe" */ "@/components/planos/PlanoUnismg.vue"
      ),
  },
  {
    path: "/planounismg2",
    name: "PlanoUnismg2",
    component: () =>
      import(
        /* webpackChunkName: "plawnoe" */ "@/components/planos/PlanoUnismg2.vue"
      ),
  },
  {
    path: "/planoviasat",
    name: "PlanoViasat",
    component: () =>
      import(
        /* webpackChunkName: "plawnoe" */ "@/components/planos/PlanoViasat.vue"
      ),
  },
  {
    path: "/planoviasat2",
    name: "PlanoViasat2",
    component: () =>
      import(
        /* webpackChunkName: "planoee" */ "@/components/planos/PlanoViasat2.vue"
      ),
  },
  {
    path: "/negocviasat",
    name: "NegocViasat",
    component: () =>
      import(
        /* webpackChunkName: "planobee" */ "@/components/bolviasat_negoc/NegocViasat.vue"
      ),
  },
  {
    path: "/boletoviasat",
    name: "BoletoViasat",
    component: () =>
      import(
        /* webpackChunkName: "planobee" */ "@/components/bolviasat_negoc/BoletoViasat.vue"
      ),
  },
  {
    path: "/planoenalin2",
    name: "PlanoEnalin2",
    component: () =>
      import(
        /* webpackChunkName: "planoe" */ "@/components/planos/PlanoEnalin2.vue"
      ),
  },
  {
    path: "/planoimpecavel",
    name: "PlanoImpecavel",
    component: () =>
      import(
        /* webpackChunkName: "planoimpecavel" */ "@/components/planos/PlanoImpecavel.vue"
      ),
  },
  {
    path: "/planoimpecavel2",
    name: "PlanoImpecavel2",
    component: () =>
      import(
        /* webpackChunkName: "planoimpecavel2" */ "@/components/planos/PlanoImpecavel2.vue"
      ),
  },
  {
    path: "/planoalgar",
    name: "PlanoAlgar",
    component: () =>
      import(
        /* webpackChunkName: "planoalgar" */ "@/components/planos/PlanoAlgar.vue"
      ),
  },
  {
    path: "/planoalgar2",
    name: "PlanoAlgar2",
    component: () =>
      import(
        /* webpackChunkName: "planoalgar2" */ "@/components/planos/PlanoAlgar2.vue"
      ),
  },
  {
    path: "/planooinova",
    name: "PlanoOiNova",
    component: () =>
      import(
        /* webpackChunkName: "planooinova" */ "@/components/planos/PlanoOiNova.vue"
      ),
  },
  {
    path: "/planooinovacpf",
    name: "PlanoOiNovaCPF",
    component: () =>
      import(
        /* webpackChunkName: "planooinova2c" */ "@/components/planos/PlanoOiNovaCPF.vue"
      ),
  },
  {
    path: "/planoalgaroferta",
    name: "PlanoAlgarOferta",
    component: () =>
      import(
        /* webpackChunkName: "planooinova2c" */ "@/components/planos/PlanoAlgarOferta.vue"
      ),
  },
  {
    path: "/planooinova2",
    name: "PlanoOiNova2",
    component: () =>
      import(
        /* webpackChunkName: "planooinova2" */ "@/components/planos/PlanoOiNova2.vue"
      ),
  },
  {
    path: "/operacao",
    name: "Operacao",
    component: () =>
      import(
        /* webpackChunkName: "operacao" */ "@/components/operacao/Operacao.vue"
      ),
  },
  {
    path: "/operacao2",
    name: "Operacao2",
    component: () =>
      import(
        /* webpackChunkName: "operacao2" */ "@/components/operacao/Operacao2.vue"
      ),
  },
  {
    path: "/demais",
    name: "AlgarDemais",
    component: () =>
      import(
        /* webpackChunkName: "algard" */ "@/components/demais/AlgarDemais.vue"
      ),
  },
  {
    path: "/demais2",
    name: "AlgarDemais2",
    component: () =>
      import(
        /* webpackChunkName: "algard2" */ "@/components/demais/AlgarDemais2.vue"
      ),
  },
  {
    path: "/demaisboleto",
    name: "AlgarDemaisBoleto",
    component: () =>
      import(
        /* webpackChunkName: "algardb" */ "@/components/demais/AlgarDemaisBoleto.vue"
      ),
  },
  {
    path: "/boletooperacaoalgar",
    name: "AlgarOperacao",
    component: () =>
      import(
        /* webpackChunkName: "algaroper2" */ "@/components/operacaoboletos/AlgarOperacao.vue"
      ),
  },
  {
    path: "/acordos",
    name: "AcordosPage",
    component: AcordosPage,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/33044983000116",
    name: "Impecavel",
    component: () =>
      import(
        /* webpackChunkName: "boleto-imp" */ "@/components/boletos/Impecavel.vue"
      ),
  },
  {
    path: "/33000118000190",
    name: "Brt",
    component: () =>
      import(/* webpackChunkName: "brt" */ "@/components/boletos/Brt.vue"),
  },
  {
    path: "/33000118000183",
    name: "BrtCamp",
    component: () =>
      import(/* webpackChunkName: "brtc" */ "@/components/boletos/Brt.vue"),
  },
  {
    path: "/00354301000017",
    name: "Dscard",
    component: () =>
      import(
        /* webpackChunkName: "dscard" */ "@/components/boletos/Dscard.vue"
      ),
  },
  {
    path: "/10233159000100",
    name: "Nalim",
    component: () =>
      import(/* webpackChunkName: "nalim" */ "@/components/boletos/Nalim.vue"),
  },
  {
    path: "/04164616000158",
    name: "OiMovel",
    component: () =>
      import(
        /* webpackChunkName: "oimovel" */ "@/components/boletos/OiCamp.vue"
      ),
  },
  {
    path: "/04164616000160",
    name: "OiCamp",
    component: () =>
      import(
        /* webpackChunkName: "oicamp" */ "@/components/boletos/OiCamp.vue"
      ),
  },
  {
    path: "/33000118000179",
    name: "OiFixo",
    component: () =>
      import(
        /* webpackChunkName: "oifixo" */ "@/components/boletos/OiFixo.vue"
      ),
  },
  {
    path: "/33000118000181",
    name: "TelemarCamp",
    component: () =>
      import(
        /* webpackChunkName: "telemarc" */ "@/components/boletos/OiFixo.vue"
      ),
  },
  {
    path: "/01575693000103",
    name: "Easycob",
    component: () =>
      import(
        /* webpackChunkName: "telemarc" */ "@/components/boletos/Easycob.vue"
      ),
  },

  {
    path: "/05423963000111",
    name: "OiTvDth",
    component: () =>
      import(/* webpackChunkName: "oitv" */ "@/components/boletos/OiTvDth.vue"),
  },
  {
    path: "/05423963000114",
    name: "TvDthCamp",
    component: () =>
      import(
        /* webpackChunkName: "oitv2" */ "@/components/boletos/OiTvDth.vue"
      ),
  },
  {
    path: "/71208516000174",
    name: "Algar",
    component: () =>
      import(/* webpackChunkName: "algar" */ "@/components/boletos/Algar.vue"),
  },
  {
    path: "/bolofertaalgar",
    name: "BolAlgarOferta",
    component: () =>
      import(
        /* webpackChunkName: "algar234" */ "@/components/bolofertaalgar/BolAlgarOferta.vue"
      ),
  },
  //======================================================
  {
    path: "/33044983000116-2",
    name: "Impecavel2",
    component: () =>
      import(
        /* webpackChunkName: "boleto-imp2" */ "@/components/boletos2/Impecavel2.vue"
      ),
  },
  {
    path: "/33000118000190-2",
    name: "Brt2",
    component: () =>
      import(/* webpackChunkName: "brt2" */ "@/components/boletos2/Brt2.vue"),
  },
  {
    path: "/33000118000183-2",
    name: "BrtCamp2",
    component: () =>
      import(/* webpackChunkName: "brtc2" */ "@/components/boletos2/Brt2.vue"),
  },
  {
    path: "/00354301000017-2",
    name: "Dscard2",
    component: () =>
      import(
        /* webpackChunkName: "dscard2" */ "@/components/boletos2/Dscard2.vue"
      ),
  },
  {
    path: "/10233159000100-2",
    name: "Nalim2",
    component: () =>
      import(
        /* webpackChunkName: "nalim2" */ "@/components/boletos2/Nalim2.vue"
      ),
  },
  {
    path: "/04164616000158-2",
    name: "OiMovel2",
    component: () =>
      import(
        /* webpackChunkName: "oimovel2" */ "@/components/boletos2/OiCamp2.vue"
      ),
  },
  {
    path: "/04164616000160-2",
    name: "OiCamp2",
    component: () =>
      import(
        /* webpackChunkName: "oicamp2" */ "@/components/boletos2/OiCamp2.vue"
      ),
  },
  {
    path: "/33000118000179-2",
    name: "OiFixo2",
    component: () =>
      import(
        /* webpackChunkName: "oifixo2" */ "@/components/boletos2/OiFixo2.vue"
      ),
  },
  {
    path: "/33000118000181-2",
    name: "TelemarCamp2",
    component: () =>
      import(
        /* webpackChunkName: "telemarc2" */ "@/components/boletos2/OiFixo2.vue"
      ),
  },

  {
    path: "/05423963000111-2",
    name: "OiTvDth2",
    component: () =>
      import(
        /* webpackChunkName: "oitv2" */ "@/components/boletos2/OiTvDth2.vue"
      ),
  },
  {
    path: "/05423963000114-2",
    name: "TvDthCamp2",
    component: () =>
      import(
        /* webpackChunkName: "oitv22" */ "@/components/boletos2/OiTvDth2.vue"
      ),
  },
  {
    path: "/71208516000174-2",
    name: "Algar2",
    component: () =>
      import(
        /* webpackChunkName: "algarv22" */ "@/components/boletos2/Algar2.vue"
      ),
  },
  //=======================================================
  {
    path: "/ofertatim/:cgc/:cpf/:contrato",
    name: "TimLogin",
    component: () =>
      import(
        /* webpackChunkName: "timlogin" */ "@/components/ofertatim/TimLogin.vue"
      ),
  },
  {
    path: "/ofertatimboleto",
    name: "BoletoTim",
    component: () =>
      import(
        /* webpackChunkName: "timbol" */ "@/components/ofertatim/BoletoTim.vue"
      ),
  },
  //============================================================
  {
    path: "/ofertaboleto/:cgc/:cpf/:contrato/:tipo",
    name: "OfertaLogin",
    component: () =>
      import(
        /* webpackChunkName: "ofertalogin" */ "@/components/ofertaboleto/OfertaLogin.vue"
      ),
  },
  //========================================================================================
  {
    path: "/ofertaoinovacpf/:cpf/:tipo",
    name: "OfertaCpfLogin",
    component: () =>
      import(
        /* webpackChunkName: "ofertalogin" */ "@/components/ofertaboleto/OfertaCpfLogin.vue"
      ),
  },
  {
    path: "/bolofertabrt",
    name: "OfertaBoletoBrt",
    component: () =>
      import(/* webpackChunkName: "ofertabol1" */ "@/components/bol/Brt.vue"),
  },
  {
    path: "/bolofertaoi",
    name: "OfertaBoletoOi",
    component: () =>
      import(
        /* webpackChunkName: "ofertabol2" */ "@/components/bol/OiCamp.vue"
      ),
  },
  {
    path: "/bolofertaoi2",
    name: "OfertaBoletoOi2",
    component: () =>
      import(
        /* webpackChunkName: "ofertabol3" */ "@/components/bol/OiFixo.vue"
      ),
  },
  {
    path: "/loginsite/:cpf2",
    name: "LoginSite",
    component: () =>
      import(
        /* webpackChunkName: "ofertabo22l3" */ "@/components/login/LoginSite.vue"
      ),
  },
  {
    path: "/bolofertaoitv",
    name: "OfertaBoletoOiTv",
    component: () =>
      import(
        /* webpackChunkName: "ofertabol4" */ "@/components/bol/OiTvDth.vue"
      ),
  },
  {
    path: "/boloic2",
    name: "BolOiAVistaCPF",
    component: () =>
      import(
        /* webpackChunkName: "boloi1cpf" */ "@/components/boloinova/BolOiAVistaCPF.vue"
      ),
  },
  {
    path: "/boloic",
    name: "BolOiAVista",
    component: () =>
      import(
        /* webpackChunkName: "boloi1" */ "@/components/boloinova/BolOiAVista.vue"
      ),
  },
  {
    path: "/boloip",
    name: "BolOiParcelado",
    component: () =>
      import(
        /* webpackChunkName: "boloi2" */ "@/components/boloinova/BolOiParcelado.vue"
      ),
  },
  {
    path: "/boloif",
    name: "BolOiFatura",
    component: () =>
      import(
        /* webpackChunkName: "boloi3" */ "@/components/boloinova/BolOiFatura.vue"
      ),
  },
  {
    path: "/bolunismg/:cpf/:contrato/:tipo",
    name: "BolUnismg",
    component: () =>
      import(
        /* webpackChunkName: "boldoia3" */ "@/components/bolunismg/LoadingUnismg.vue"
      ),
  },
  {
    path: "/bolunismg2",
    name: "BolUnismg2",
    component: () =>
      import(
        /* webpackChunkName: "bolodi3s" */ "@/components/bolunismg/UnismgBol2.vue"
      ),
  },
  {
    path: "/bolviasat/:cpf/:contrato/:tipo",
    name: "BolViaSat",
    component: () =>
      import(
        /* webpackChunkName: "boloia3" */ "@/components/bolviasat/LoadingViaSat.vue"
      ),
  },
  {
    path: "/bolviasat2",
    name: "BolViaSat2",
    component: () =>
      import(
        /* webpackChunkName: "boloi3s" */ "@/components/bolviasat/ViaSatBol2.vue"
      ),
  },
  {
    path: "/bolaegea",
    name: "BolAegea",
    component: () =>
      import(
        /* webpackChunkName: "boloi1cpf" */ "@/components/aegea/BolAegea.vue"
      ),
  },
  {
    path: "/aegealoding/:deonde",
    name: "LoadingAegea",
    component: () =>
      import(
        /* webpackChunkName: "boloi1d" */ "@/components/aegea/AegeaLoading.vue"
      ),
  },
  //===============================================================
  {
    path: "*",
    name: "ErroPage",
    component: () =>
      import(/* webpackChunkName: "erro" */ "../views/ErroPage.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
