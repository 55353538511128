<template>
  <v-container fluid class="bg234 pa-0">
      <v-row class="justify-space-around">
        <v-col
        cols="12" sm="8" md="4"
          class="order-1 order-md-0 text-center "
        >
          <v-avatar size="100">
            <img src="@/assets/login/call-center.png" />
          </v-avatar>
          <p
              style="font-family: kelly slab !important;"
              class="text-subtitle2 letras"
            >
              <br />Somos a Easycob,<br />
              uma empresa com mais de <br />
              26 anos de atuação na área <br />
              de recuperação de crédito.
              <br /><br />
              Trabalhamos com diversos parceiros,<br /> 
              incluindo empresas de telefonia,<br />
              água, internet, saneamento, entre outros.<br /><br />
              Estamos com ótimas ofertas para você <br />
              quitar seus débitos com descontos.
            </p>
        </v-col>
        <v-col
          class="text-center align-center justify-center order-0 order-md-1 "
          cols="12" sm="8" md="4"
        >
          <img
            src="@/assets/login/easycob.svg"
            alt="Logo da Easycob"
            width="230px"
            class="pt-2"
          />
          <v-card class="rounded-xl mx-6" >
            <div class="text-subtitle1 font-weight-light py-10" style="color: #2899D6;">
              Aproveite a oportunidade de negociar seu débito! <br />
              Informe seu <strong>CPF</strong> ou <strong>CNPJ</strong> abaixo
              para ver a oferta.
            </div>
            <v-card-actions>
              <v-row no-gutters>
                <v-text-field
                  placeholder="CPF/CNPJ"
                  outlined
                  v-model="login"
                ></v-text-field>
                <v-btn dark x-large color="primary" @click="enviar"> 
                  <v-icon large>mdi-arrow-right-bold</v-icon> 
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { api } from "@/conf/api";

export default {
  name: "LoginPage2",
  data() {
    return {
      login: "",
    };
  },
  methods: {
    coloca(a, c) {
      this.$store.dispatch("insereDividas2", a);
      this.$store.dispatch("insereCPF", c);
    },
    async enviar() {
      // achar o boleto

      this.$store.commit("zeraMessage");
      let cpf = this.login.replace(/\D/gi, "");
      if (!cpf) {
        this.$store.commit("showMessage", {
          text: "Não Achamos Boleto com essa Senha",
          color: "warning",
          show: true,
        });
        this.login = "";
        return;
      }
      await api.post("Logs/inserecpflog",{cpf})
      let res = await api.get("logs/pegarboleto/" + cpf);
      if (res.data.dados.length == 0) {
        this.$router.push("ErroPage");
      }
      this.$store.commit("setBoleto", res.data.dados[0]);
      let vai = res.data.dados[0].cgc + "-2";
      this.$router.push(vai);
      //console.log(res.data.dados[0]);
    },
  },
  mounted() {
    this.$store.dispatch("setMostraBotoes", { imprimir: false, voltar: false });
  },
};
</script>

<style scoped>
.letras {
  font-size: 18px;
  color: white;
  padding: 20px;
  border-radius: 25px;
  text-shadow: 1px 1px 1px black;
}
.bg234 {
  background: url("../../assets/login/fundo.png");
  background-size: cover;
  height: calc(100vh + 450px);
  background-color: #2899d6;
}

.ctn-apresentação {
  font-size: 18px;
  color: white;
  padding: 20px;
  border-radius: 25px;
  text-shadow: 1px 1px 1px black;
  width: 300px !important;
}
.img-card2 {
  top: 5px;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.3);
  height: 100px;
  border-radius: 50px 50px 50px 50px;
  background-color: rgb(255, 255, 255);
}
.ctn-img-card {
  height: 533px !important;
  padding: 20px;
}
.parte2 {
  color: #2899d6;
}
</style>
