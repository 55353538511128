<template>
  <v-footer dark padless class="d-print-none text-center" app>
    <v-card color="black" flat tile v-if="$store.state.aparecebanner">
      <v-container fluid>
        <v-card color="black" flat tile>
          <v-row no-gutters>
            <v-col cols="9" xs="12" class="text-start">
              <p class="text-body2">Consentimento do cookie</p>
              <p class="d-inline text-caption">
                Usamos cookies em nosso site para fornecer a experiência mais
                relevante, lembrando suas preferências. Ao clicar em "Aceitar",
                concorda com a utilização de TODOS os cookies. Para mais
                informações, consulte aqui
              </p>
              <a
                href="https://easycob.com.br/lgdp.pdf"
                target="_blank"
                class="white--text"
                >Política de Privacidade</a
              >
            </v-col>
            <v-col class="d-flex justify-end align-end">
              <v-row>
                <v-col class="d-flex justify-end align-end">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="orange"
                    class="ma-1"
                    @click="$store.commit('setDialogo', true)"
                    >Preferências</v-btn
                  >
                  <v-btn
                    color="primary"
                    class="ma-1"
                    @click="$store.commit('setApareceBanner', false)"
                    >Aceitar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
    <!-- -->
    <v-card class="flex text-center pa-0" flat >
      <v-card-title class="primary pa-1" v-if="$store.state.mostraImprimir">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-1"
          small
          outlined
          v-if="$store.state.mostraVoltar"
          @click="voltar"
        >
          Voltar para Negoc
        </v-btn>
        <v-btn
          class="mx-1"
          small
          outlined
          v-if="$store.state.mostraImprimir"
          @click="imprimir"
        >
          Imprimir
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text class="white--text text-end" style="padding: 2px !important;">
        <v-row>
          <v-col sm="6" cols="12" class="d-flex justify-center justify-sm-end px-1">
            boleto@easboleto.com.br - (021) 2206-4883 
           
          </v-col>
          <v-col class="d-flex justify-center justify-sm-end px-1" sm="6" cols="12" >
            <div class="contato d-flex gap-2">
              <a href="https://www.easycob.com.br/" target="_blank" class="px-2"
                ><img
                  src="@/assets/login/internet.png"
                  width="25px"
                  title="Site"
                  
              /></a>

              <a
                href="https://api.whatsapp.com/send?phone=552122064811&text=Estive%20no%20oferta%20e%20quero%20negociar%20meu%20d%C3%A9bito"
                target="_blank" class="px-2"
                ><img
                  src="@/assets/login/whatsapp_ti.png"
                  width="25px"
                  title="WhatsApp"
              /></a>

              <a href="https://t.me/Easycob_bot" target="_blank" class="px-2"
                ><img
                  src="@/assets/login/telegram_ti.png"
                  width="25px"
                  title="Telegram"
              /></a>

              <a href="https://twitter.com/Easycoboficial" target="_blank" class="px-2"
                ><img src="@/assets/login/x.png" width="25px" title="X"
              /></a>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "MeuFooter",

  methods: {
    imprimir: function() {
      window.print();
    },
    voltar: function() {
      // this.$router.push("dividas");
      this.$router.back();
    },
  },
};
</script>

<style scoped>
#linkmeu {
  text-decoration: none;
  color: white;
}
</style>
