<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="py-5" elevation="8">
          <h1
            class="text-center primary--text text-xl-h4 text-lg-h4   text-md-h4 text-sm-h5 text-xs-body-1"
            style="font-family: Kelly Slab !important"
          >
            Ofertas para o CPF/CNPJ {{ $store.state.cpf }}
          </h1>
          <h4 class="text-center">
            Data:
            {{
              new Date()
                .getDate()
                .toString()
                .padStart(2, "0")
            }}/ {{ (new Date().getMonth() + 1).toString().padStart(2, "0") }}/
            {{ new Date().getFullYear() }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
    <DividasList />
  </v-container>
</template>

<script>
import DividasList from "./DividasList";

export default {
  name: "DividasPage",
  components: { DividasList },
  mounted() {
    this.$store.dispatch("setMostraBotoes", { imprimir: false, voltar: false });
  },
};
</script>

<style></style>
