const convert = require("xml-js");
const options = { compact: true, spaces: 4 };

let login = {};
login.uid = "EASYCOB";
login.pwd = "ALGAR@2020";
login.empresa = "CTBC";

const XML2JS = function(str) {
  return convert.xml2js(str, options);
};

const JS2XML = function(obj) {
  return convert.js2xml(obj, options);
};

let datahoje = function() {
  let dataagora = new Date();
  if (dataagora.getDay() == 0) {
    dataagora.setDate(dataagora.getDate() + 1);
  }
  if (dataagora.getDay() == 6) {
    dataagora.setDate(dataagora.getDate() + 2);
  }
  let dia = dataagora
    .getDate()
    .toString()
    .padStart(2, "0");
  let mes = (dataagora.getMonth() + 1).toString().padStart(2, "0");
  let ano = dataagora.getFullYear().toString();
  return `${ano}-${mes}-${dia}`;
};

let datavencimento = function(quantosDias=3) {
  let dataagora = new Date();
  dataagora.setDate(dataagora.getDate() + quantosDias);

  if (dataagora.getDay() == 0) {
    dataagora.setDate(dataagora.getDate() + 1);
  }
  if (dataagora.getDay() == 6) {
    dataagora.setDate(dataagora.getDate() + 2);
  }
  let dia = dataagora
    .getDate()
    .toString()
    .padStart(2, "0");
  let mes = (dataagora.getMonth() + 1).toString().padStart(2, "0");
  let ano = dataagora.getFullYear().toString();
  return `${ano}-${mes}-${dia}`;
};

let CalculaDigitoMod10 = function(Dado) {
  var i;
  var mult = 2;
  var soma = 0;
  var s = "";

  for (i = Dado.length - 1; i >= 0; i--) {
    s = mult * parseInt(Dado.charAt(i)) + s;
    if (--mult < 1) {
      mult = 2;
    }
  }
  for (i = 0; i < s.length; i++) {
    soma = soma + parseInt(s.charAt(i));
  }
  soma = soma % 10;
  if (soma != 0) {
    soma = 10 - soma;
  }
  return soma.toString();
};

const cod_barras_oper_pronto = function(linha_dig) {
  let ld = linha_dig.trim();

  let cb_aux = ld.replace(/\D/gim, "");

  let c1 = cb_aux.substr(0, 11);
  let c2 = cb_aux.substr(12, 11);
  let c3 = cb_aux.substr(24, 11);
  let c4 = cb_aux.substr(36, 11);

  let cb = c1 + c2 + c3 + c4;

  return { cb, ld };
};

const cod_barras_oper_48 = function(linha_dig) {
  let c1 = linha_dig.substr(0, 11);
  let c2 = linha_dig.substr(12, 11);
  let c3 = linha_dig.substr(24, 11);
  let c4 = linha_dig.substr(36, 11);

  let d1 = linha_dig.substr(11, 1);
  let d2 = linha_dig.substr(23, 1);
  let d3 = linha_dig.substr(35, 1);
  let d4 = linha_dig.substr(47, 1);

  let cb = c1 + c2 + c3 + c4;

  let ld = `${c1}-${d1} ${c2}-${d2} ${c3}-${d3} ${c4}-${d4}`;
  return { cb, ld };
};

const cod_barras_mod10 = function(linha) {
  let bloco1 = linha.substr(0, 11);
  let bloco2 = linha.substr(11, 11);
  let bloco3 = linha.substr(22, 11);
  let bloco4 = linha.substr(33, 11);

  let d1 = CalculaDigitoMod10(bloco1);
  let d2 = CalculaDigitoMod10(bloco2);
  let d3 = CalculaDigitoMod10(bloco3);
  let d4 = CalculaDigitoMod10(bloco4);

  let cb = linha;
  let ld = `${bloco1}-${d1} ${bloco2}-${d2} ${bloco3}-${d3} ${bloco4}-${d4}`;

  return {
    cb,
    ld,
  };
};

const casasDecimais = function(num, precisao) {
  var casas = Math.pow(10, precisao);
  return Math.floor(num * casas) / casas;
};

const dataVenc = function(dataP,quantosDias=5) {
  let data0 = dataP;

  data0.setDate(data0.getDate() + quantosDias);

  if (data0.getDay() == 0) {
    data0.setDate(data0.getDate() - 2);
  }
  if (data0.getDay() == 6) {
    data0.setDate(data0.getDate() - 1);
  }
  return data0.toISOString().substr(0, 10);
};

function pegarParcXML(str, parc, valor, percentualDesc, Desc) {
  // 0, "0,00", "S", "90,00");
  let calculo = str.xml.calculo;
  let login = str.xml.login;
  let prest = str.xml.prest;
  //console.log(prest);
  let produto,contrato;
  if(Array.isArray(prest)){
    produto = prest[0].produt._text;
    contrato = prest[0].contr._text;
    
  }else{
    produto = prest.produt._text;
    contrato = prest.contr._text;

  }

  
  let contratonrc = {
    produto,
    contrato,
  };
  let endereconrc = {
    produto,
    contrato,
    codigo: "FATURAMEN",
  };

  let calculoparc = {};

  calculoparc.dtentrada = datavencimento();
  calculoparc.qtdparc = parc;
  calculoparc.valentrada = valor;
  calculoparc.descpercentual = percentualDesc;
  calculoparc.desconto = Desc;
  calculoparc.renegociacao = "N";
  calculoparc.propostaacordo = "N";
  calculoparc.datasmensais = "S";
  calculoparc.parcelasiguais = "S";
  calculoparc.todasnrc = "N";
  calculoparc.tipoenviobol = "";
  calculoparc.formapagtoentrada = "B";
  calculoparc.dtpagamentodi = "";
  calculoparc.valoradicional = 0;

  let xml = {
    calculo,
    login,
    prest,
    contratonrc,
    endereconrc,
    calculoparc,
  };

  let objMeu = {
    xml,
  };

  let objXML = convert.js2xml(objMeu, options);
  return {
    objMeu,
    objXML,
  };
}

function pegarParcXML2(str, parc, valor, percentualDesc, Desc, vencimento) {
  // 0, "0,00", "S", "90,00");
  let calculo = str.xml.calculo;
  let login = str.xml.login;
  let prest = str.xml.prest;
  //console.log(prest);

  let produto = prest[0].produt._text;
  let contrato = prest[0].contr._text;
  let contratonrc = {
    produto,
    contrato,
  };
  let endereconrc = {
    produto,
    contrato,
    codigo: "FATURAMEN",
  };

  let calculoparc = {};

  calculoparc.dtentrada = vencimento;
  calculoparc.qtdparc = parc;
  calculoparc.valentrada = valor;
  calculoparc.descpercentual = percentualDesc;
  calculoparc.desconto = Desc;
  calculoparc.renegociacao = "N";
  calculoparc.propostaacordo = "N";
  calculoparc.datasmensais = "S";
  calculoparc.parcelasiguais = "S";
  calculoparc.todasnrc = "N";
  calculoparc.tipoenviobol = "";
  calculoparc.formapagtoentrada = "B";
  calculoparc.dtpagamentodi = "";
  calculoparc.valoradicional = 0;

  let xml = {
    calculo,
    login,
    prest,
    contratonrc,
    endereconrc,
    calculoparc,
  };

  let objMeu = {
    xml,
  };

  let objXML = convert.js2xml(objMeu, options);
  return {
    objMeu,
    objXML,
  };
}

function pegarBoletoXML(cpf, acordo, venc, numprest) {
  let objXML = `
      <xml>
  <calculo>
    <dtpagto>${venc}</dtpagto>
    <pctdesconto>0</pctdesconto>
    <valdesconto>0</valdesconto>
    <credor>1</credor>
    <regis>${cpf}</regis>
    <descdespesa>N</descdespesa>
    <deschonorarios>N</deschonorarios>
    <operesp></operesp>
    <valoradicional>0</valoradicional>
  </calculo>
  <prest>
    <produt>PARC</produt>
    <contr>${acordo}</contr>
    <vencto>${venc}</vencto>
    <NUMPREST>${numprest}</NUMPREST>
  </prest>
  <login>
    <uid>EASYCOB</uid>
    <pwd>ALGAR@2020</pwd>
    <empresa>CTBC</empresa>
  </login>
</xml>
  `;
  let objMeu = convert.xml2js(objXML, options);
  return {
    objMeu,
    objXML,
  };
}

const addDays = function(date,days){
  if(typeof date == 'string') date = new Date(date)
  date.setDate(date.getDate() + days)
  return date
}

const arredonda = function(saldo){
  let valor
  let meuarray = saldo.toString().split('.')
  let decimais = '00'
  if(meuarray.length>1){
    decimais = meuarray[1]
    if(decimais.length==1) decimais = decimais +'0'
  }
  valor = meuarray[0]+'.'+decimais.substring(0,2)
  return parseFloat(valor)

}

const calcDescViasat = function(saldo,desc1,desc2,atraso){
  // let atraso = 400
let desc = desc1
if(atraso>365) desc = desc2

//let saldo = 1000
let valor = saldo - (saldo * (desc/100))
console.log(valor)
while(valor<100 && valor<=saldo && desc>=0){
   valor = saldo - (saldo * (desc/100))
   if(desc==0) break 
   desc--
  // console.log(valor,saldo,desc,atraso)
}

return {valor:arredonda(valor),saldo,desc,atraso}

}

const quantosDiasVenc = (d1,d2)=>{
  var date1 = new Date(d1);
  var date2 = new Date(d2);
  var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
  //console.log(diffDays);
  return diffDays
}

const calcDividaUnismg = (dataum)=>{
  let d1 = dataum
  let d2 = datavencimento(7)
  let diffDias = quantosDiasVenc(d1,d2)
  return {dias: diffDias,d1,d2 }

}

module.exports = {
  cod_barras_oper_48,
  cod_barras_mod10,
  cod_barras_oper_pronto,
  casasDecimais,
  dataVenc,
  datahoje,
  datavencimento,
  XML2JS,
  JS2XML,
  pegarParcXML,
  pegarParcXML2,
  pegarBoletoXML,
  addDays,
  arredonda,
  calcDescViasat,
  quantosDiasVenc,
  calcDividaUnismg

};
