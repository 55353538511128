<template>
  <v-container>
    <v-row>
      <v-col class="text-center" cols="12" v-if="temConsolidado">
        <v-card class="pa-5">
          ghfgh
        </v-card>
      </v-col>
      <v-col class="text-center" cols="12" v-if="temParcelado">
        <v-card class="pa-5">
          ghfgh
        </v-card>
      </v-col>
      <v-col class="text-center" cols="12" v-if="temFatura">
        <v-card class="pa-5">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">OVERLINE</div>
              <v-list-item-title class="headline mb-1"
                >Headline 5</v-list-item-title
              >
              <v-list-item-subtitle
                >Greyhound divisely hello coldly
                fonwderfully</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//<div>{{ $store.state.negoc_atual }} - {{ $store.state.pacote_atual }}</div>

export default {
  name: "AcordosPage",
  data() {
    return {
      temFatura: false,
      temConsolidado: false,
      temParcelado: false,
    };
  },
  beforeMount() {
    this.temFatura = false;
    this.temConsolidado = false;
    this.temParcelado = false;

    if (this.$store.state.pacote_atual == "C") {
      this.temConsolidado = true;
    }
    if (this.$store.state.pacote_atual == "P") {
      this.temParcelado = true;
    }
    if (this.$store.state.pacote_atual == "F") {
      this.temFatura = true;
    }
  },
};
</script>

<style></style>
