import axios from "axios";

export const api = axios.create({
  //baseURL: "https://tesb-api.easycob-n.com.br/", // api de teste
  baseURL: "https://boleto-api.easycob-n.com.br/", // api de prod
  //baseURL: 'http://localhost:3000',
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json; charset=utf-8",
  },
});
