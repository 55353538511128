<template>
  <div>
  <v-row>
    <v-col cols="12">
      <h3>
        Total de {{ $store.state.dividas.length }} Oferta<span
          v-if="$store.state.dividas.length > 1"
          >s</span
        >
      </h3>
    </v-col>
    <v-col v-for="(item, i) in div" :key="i">
      <DividasItem :item="item" />
    </v-col>
  </v-row>
 
  </div>
</template>

<script>
import DividasItem from "./DividasItem";

export default {
  name: "DividasList",
  components: { DividasItem },

  computed: {
    div() {
      return this.$store.state.dividas;
    },
  },
};
</script>

<style></style>
