<template>
  <div>
  <v-card class="mx-auto my-2" max-width="344" elevation="6" :loading="loading">
    <v-card-title style="font-family: Kelly Slab !important">{{
      item.credor
    }}</v-card-title>

    <v-card-text>
      <v-row align="center" class="mx-0">
        <v-rating
          :value="5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>
      </v-row>

      <div class="my-2 subtitle-1">
        {{ item.nome.substr(0, 40) }}
      </div>

      <div>
        Contrato {{ item.contrato }} <br />
        {{ item.diasatraso }} dias de atraso
      </div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title
      >Valor: R$
      {{ item.cpsaldev | dinheiro }}
      <br />{{ item.credor == "LOJAS NALIN" ? " não atualizado" : "*" }}
    </v-card-title>

    <v-card-actions>
      <v-btn color="primary" @click="pegaDivida(item.contrato, item.credor)">
        Ver Detalhes desta Oferta
      </v-btn>
    </v-card-actions>
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
  </v-card>
  <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  
  </div>
</template>

<script>
import { api } from "@/conf/api";
import{calcDividaUnismg} from '../../conf/utilitario'

export default {
  name: "DividasItem",
  data() {
    return {
      loading: false,
      overlay: false
    };
  },
  props: ["item"],
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
  },
  async mounted() {
   if(this.item.cpcgc!='31404878000116')return
   try{
    this.overlay=true
    let cpf = this.item.cpcpfcgc.trim()
    let contrato = this.item.contrato.trim()
    let cgc = this.item.cpcgc.trim()
    let remessa = this.item.cpdatrem
    let codacordo = this.item.cpcodacordo
    //console.log(this.item.cpsaldev,cpf,cgc,contrato,remessa,codacordo)
    let resp = await api.post('unismg/dadosparcelacriterio',{cpf,cgc,contrato,remessa,codacordo})
    //console.log('resp',resp.data)
    let faturas = resp.data.dados.faturas
    this.$store.dispatch("insereUnismgFaturas", faturas);
    let criterios = resp.data.dados.criterios[0]
    this.$store.dispatch("insereUnismgCriterios", criterios);
   // console.log('crit',this.$store.state.unismg_criterios)
    let var_mora = parseFloat(criterios.perc_mora)
    let multa = parseFloat(criterios.perc_multa)
    let desc_avista =parseFloat( criterios.perc_desconto_avista)
    let desc_encargos = parseFloat(criterios.perc_desc_encargos)
    let desc_parc = parseFloat(criterios.perc_desconto_parc)
    let perc_entrada = parseFloat(criterios.perc_entrada)
    let parc_min = parseFloat(criterios.parcela_minima)
    //console.log(desc_avista,desc_encargos,desc_parc,perc_entrada,parc_min)
    //---------calc da dívida------------------------------------------------
    let qtdeFaturas = faturas.length
    //let agora = calcDividaUnismg()
    //console.log('dias',agora)
    let totalFaturas=0,  moraTotal=0, multaTotal=0;
    let dataVenc;

    faturas.forEach(e => {

      totalFaturas = totalFaturas + parseFloat( e.pdvalor)
      let difdatas = calcDividaUnismg(e.pddtvencto.substring(0,10))
      dataVenc = difdatas.d2

      let valor1 = parseFloat(e.pdvalor) * difdatas.dias
      let mora_por_fatura = ((var_mora/30)/100)* valor1
     let multa_por_fatura= (parseFloat(e.pdvalor) * multa)/100 

     multaTotal= multaTotal + multa_por_fatura
      moraTotal = moraTotal+mora_por_fatura
    });
    //console.log('result',totalFaturas,qtdeFaturas,moraTotal,multaTotal)
    //console.log('result2',typeof totalFaturas,typeof qtdeFaturas,typeof moraTotal,typeof multaTotal)
    let divida_atualizada = totalFaturas+ moraTotal+multaTotal
    //console.log('div',divida_atualizada)
    divida_atualizada = Math.ceil(divida_atualizada * 100) / 100;
    //console.log('div',typeof divida_atualizada,divida_atualizada,this.item.cpsaldev)
    //let divida_str = divida_atualizada.toString().split('.')
    //let divida_str2 = divida_str[0] +'.'+ divida_str[1].substring(0,2)
    let divida_ori = this.item.cpsaldev
    this.item.cpsaldev = divida_atualizada  //parseFloat(divida_str2)

    let pri = totalFaturas * (desc_avista/100)
    let pri2 = moraTotal + multaTotal
    let pri3 = (moraTotal + multaTotal) * (desc_encargos/100)
    //console.log('conta',totalFaturas,pri,pri2,pri3)
    let _1x =(totalFaturas - pri) + (pri2 - pri3)
    //console.log('1x',_1x)
    _1x = Math.ceil(_1x * 100) / 100;
    //console.log('1x',_1x)
    //console.log('puto',totalFaturas,desc_avista,moraTotal,multaTotal,desc_encargos)

    let p1 = totalFaturas - (totalFaturas * (desc_parc/100)) + ( pri2 - pri3) 
    
    let p2 = (perc_entrada/100)
    //console.log('3xp',p1,p2)
    //let _3xentrada =  totalFaturas - ((totalFaturas * (desc_parc/100)) - (moraTotal + multaTotal) + ((desc_encargos/100) *  (perc_entrada/100)))
    let entrada = p1* p2
    //console.log('entrada',entrada)
    entrada = Math.ceil(entrada * 100) / 100;

    let diferenca = p1 - entrada
    diferenca = Math.ceil(diferenca * 100) / 100;
    //console.log(diferenca,dataVenc)
    

    let _3xdemais = diferenca /2
    //console.log('demais3',_3xdemais)
    _3xdemais = Math.ceil(_3xdemais * 100) / 100;
    //console.log('3xdemais',_3xdemais)
    let _5xdemais = diferenca/4
    //console.log('demais5',_5xdemais)
    _5xdemais = Math.ceil( _5xdemais * 100) / 100;
    
    //console.log('5xdemais',_5xdemais) insereUnismgValores
    //console.log(divida_atualizada,divida_ori,_1x,entrada,_3xdemais,_5xdemais,dataVenc,qtdeFaturas,parc_min)
    let obj = {divida_atualizada,divida_ori,_1x,entrada,_3xdemais,_5xdemais,dataVenc,qtdeFaturas,parc_min,codacordo}
    this.$store.dispatch("insereUnismgValores", obj);
    //---------------------------------------------------------
    //this.item.cpsaldev = 23.6
    this.overlay=false
   }catch (e) {
      console.log("err", e.message);
      this.$router.push({ name: "ErroPage" });
    }
  },

  methods: {
    async pegaDivida(contratoAtual, credorAtual) {
      this.loading = true;
      let divida = this.$store.state.dividas.filter(function(divida) {
        return divida.contrato == contratoAtual && divida.credor == credorAtual;
      });
      this.$store.dispatch("marcaDividaAtual", divida[0]);

      let credor = this.$store.state.divida_atual.credor;
      /*
      if (divida[0].cpcgc == "05423963000111") {
        return (window.location = "https://www.oi.com.br/negociacao");
      }
      */
      //https://www.timnegocia.com.br/

      //window.open('https://www.timnegocia.com.br', '_blank');

      if (
        divida[0].cpcgc == "04206050000180" ||
        divida[0].cpcgc == "04206050000181"
      ) {
        return (window.location = "https://www.timnegocia.com.br");
      }

      try {
        await this.$store.dispatch("marcaCredorAtual", credor);

        let repac = false;
        if (
          divida[0].credor2 == "OI MOVEL" ||
          divida[0].credor2 == "OI TV DTH" ||
          divida[0].credor2 == "OI CAMPANH" ||
          divida[0].credor2 == "TELEMARCAM" ||
          divida[0].credor2 == "TV DTH CAM" ||
          divida[0].credor2 == "CABO CAMP" ||
          divida[0].credor2 == "OI FIXO"
        ) {
          repac = true;
        }

        let dados = {
          cgc: divida[0].cpcgc,
          cpf: divida[0].cpcpfcgc,
          contrato: divida[0].contrato,
          remessa: divida[0].cpdatrem,
          repac,
        };

        let resposta = await api.post("logs/conferenegoc", dados);
        // console.log(dados);
        // console.log(resposta.data.dados.length);
        if (resposta.data.dados.length != 0 && dados.cgc != "71208516000174") {
          //this.$router.push({ name: "ErroPage" });
          throw new Error("Oooops!");
        }

        this.loading = false;
        this.$router.push({ name: "PlanosPage" });
      } catch (e) {
        this.$router.push({ name: "ErroPage" });
      }
    },
  },
};
</script>

<style></style>
