<template>
  <v-container fluid class="bg234 pa-0">
    <v-row class="justify-space-around">
      <v-col cols="12" sm="8" md="4" class="order-1 order-md-0 text-center ">
        <v-avatar size="100">
          <img src="@/assets/login/call-center.png" />
        </v-avatar>
        <p
          style="font-family: kelly slab !important;"
          class="text-subtitle2 letras"
        >
          <br />Somos a Easycob,<br />
          uma empresa com mais de <br />
          26 anos de atuação na área <br />
          de recuperação de crédito.
          <br /><br />
          Trabalhamos com diversos parceiros,<br />
          incluindo empresas de telefonia,<br />
          água, internet, saneamento, entre outros.<br /><br />
          Estamos com ótimas ofertas para você <br />
          quitar seus débitos com descontos.
        </p>
      </v-col>
      <v-col
        class="text-center align-center justify-center order-0 order-md-1 "
        cols="12"
        sm="8"
        md="4"
      >
        <img
          src="@/assets/login/easycob.svg"
          alt="Logo da Easycob"
          width="230px"
          class="pt-2"
        />
        <v-card class="rounded-xl mx-6">
          <div
            class="text-subtitle1 font-weight-light py-10"
            style="color: #2899D6;"
          >
            Aproveite a oportunidade de negociar seu débito! <br />
            Informe seu <strong>CPF</strong> ou <strong>CNPJ</strong> abaixo
            para ver a oferta.
          </div>
          <v-card-actions>
            <v-row no-gutters>
              <v-text-field
                placeholder="CPF/CNPJ"
                outlined
                v-model="login"
              ></v-text-field>
              <v-btn dark x-large color="primary" @click="enviar">
                <v-icon large>mdi-arrow-right-bold</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { api } from "@/conf/api";

export default {
  name: "LoginPage",
  data() {
    return {
      login: "",
    };
  },
  mounted() {
    this.$store.dispatch("setMostraBotoes", { imprimir: false, voltar: false });
  },
  methods: {
    coloca(a, c) {
      this.$store.dispatch("insereDividas2", a);
      this.$store.dispatch("insereCPF", c);
    },
    async enviar() {
      this.$store.commit("zeraMessage");
      let cpf = this.login.replace(/\D/gi, "");

      // cpf = "00000048712";

      if (!cpf) {
        this.$store.commit("showMessage", {
          text: "Coloque um CPF ou CNPJ para ver as Ofertas",
          color: "warning",
          show: true,
        });
        this.login = "";
        return;
      }
      try {
        await api.post("Logs/inserecpflog", { cpf });

        let res = await api.get("dividas/" + cpf);

        if (res.data.dados.length == 0) {
          this.$store.commit("showMessage", {
            text:
              "Não Encontramos Ofertas para Você! Entre em contato conosco. Informações no rodapé da página",
            color: "warning",
            show: true,
          });
          this.login = "";
        } else {
          this.coloca(res.data.dados, cpf);

          this.$router.push("dividas");
        }
        //------------------------
      } catch (e) {
        this.$store.commit("showMessage", {
          text: "Erro inesperado! Tente daqui à pouco!",
          color: "warning",
          show: true,
        });

        return;
      }
    },
  },
};
</script>

<style scoped>
.letras {
  font-size: 18px;
  color: white;
  padding: 20px;
  border-radius: 25px;
  text-shadow: 1px 1px 1px black;
}
.bg234 {
  background: url("../../assets/login/fundo.png");
  background-size: cover;
  height: calc(100vh + 450px);
  background-color: #2899d6;
}

.ctn-apresentação {
  font-size: 18px;
  color: white;
  padding: 20px;
  border-radius: 25px;
  text-shadow: 1px 1px 1px black;
  width: 300px !important;
}
.img-card2 {
  top: 5px;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.3);
  height: 100px;
  border-radius: 50px 50px 50px 50px;
  background-color: rgb(255, 255, 255);
}
.ctn-img-card {
  height: 533px !important;
  padding: 20px;
}
.parte2 {
  color: #2899d6;
}
</style>
